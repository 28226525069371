<template>
  <div>
    <div
      class="hero-image mt-3"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
    >
      <div class="hero-text">
        <h1>360° optimal health</h1>
      </div>
    </div>

    <featured-in></featured-in>

    <div class="body-content mx-auto">
      <!-- Main header Section -->
      <div class="container-fluid">
        <div class="row mt-5">
          <div class="col-md-12 mx-auto text-center">
            <div class="h3 mb-2 text-green text-center">
              A health programme designed to support you to long-term optimal
              health
            </div>
            <p style="font-weight: 800" class="mb-5">
              To achieve this we focus on 3 areas vital for your health:
            </p>
          </div>
        </div>
      </div>

      <!-- Circle Slider -->
      <div class="container my-2 circle_slider mb-5" data-slider-id="1">
        <div class="row noselect">
          <div class="col-auto mx-auto">
            <!-- large green inner circle left -->
            <div
              class="green_circle inner_circle d-none d-lg-inline-block"
              data-position="inner_left"
            >
              <div class="green_circle_inner_left_text"></div>
              <div class="green_circle_inner_arrow"></div>
            </div>
            <div class="circle_slider_mobile_elements">
              <!-- left arrow on mobiles -->
              <div class="slider-arrow d-lg-none mx-3" data-rotate="left">
                <i class="fa fa-angle-left fa-4x" style="color: grey"></i>
              </div>
              <!-- large selected centre circle -->
              <div class="selected_circle selected_circle_blue">
                <div
                  class="selected_circle_text selected_circle_text_blue"
                ></div>
                <div
                  class="selected_circle_arrow selected_circle_arrow_blue"
                ></div>
              </div>
              <!-- right arrow on mobiles -->
              <div class="slider-arrow d-lg-none mx-3" data-rotate="right">
                <i class="fa fa-angle-right fa-4x" style="color: grey"></i>
              </div>
            </div>
            <!-- large green inner circle right -->
            <div
              class="green_circle inner_circle d-none d-lg-inline-block"
              data-position="inner_right"
            >
              <div class="green_circle_inner_right_text"></div>
              <div class="green_circle_inner_arrow"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-9 mx-auto">
            <!-- large selected centre circle subheader and description -->
            <div class="text-center">
              <div
                class="selected_circle_subheader selected_circle_subheader_blue my-3"
              ></div>
              <div class="selected_circle_description blue-text"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div class="col-md-9 mx-auto">
            <p class="darkgreen-text text-center">
              <i>
                <span style="font-weight: 800">
                  These 3 parts can work individually or collaboratively and
                  with this information we are able to create a personalised
                  health plan tailored to your health needs and health goals.
                </span>
              </i>
            </p>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Personalised For You Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="row">
              <div class="col-md-7 col-lg-9 text-center">
                <span class="h3 mb-4 text-center d-block text-green"
                  >Your personalised health plan</span
                >
                <p class>
                  To support you to achieve 360 ̊ optimal health we operate a
                  series of functional tests and produce you a personalised plan
                  tailored to your needs and goals. These advanced tests include
                  urine, saliva, blood and stool analysis. Your samples are sent
                  to our partner laboratories and give us you unique
                  ‘fingerprint’ of health helping us to form your personalised
                  plan.
                </p>
                <p class>
                  We support the analysis of these tests with our skilled
                  functional medicine practitioners.
                </p>
                <p class>
                  The advanced tests that we work with are not readily available
                  on the NHS. The tests we do offer would only normally be
                  available in the NHS from; endroconologists for hormone
                  health, gastro-intestinal consultants for gut health and
                  Geneticist for genetic health. It would normally take you
                  months or years to become ‘ill enough’ to be referred to these
                  experts.
                </p>
                <p class>
                  <span style="font-weight: 800"
                    >We combine naturopathic principles with functional
                    medicine.</span
                  >
                </p>
              </div>
              <div class="col-md-5 col-lg-3 mb-5 mb-md-0">
                <img
                  width="100%"
                  src="@/assets/images/pages/home/personalised-image.jpg"
                  alt="Personalised image"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row my-5">
          <div class="col-md-9 mx-auto pb-lg-30 text-center">
            <span class="darkgreen-text h3 d-block mb-3"
              >What is Functional Medicine?</span
            >
            <p class>
              Functional medicine is a private health model that is the future
              of health for the 21st Century.
            </p>
            <p class>
              Functional medicine tackles the underlying causes of disease and
              poor health. It is an evolution in the practice of medicine that
              better addresses the healthcare needs of the 21st century.
              Conventional medicine tends to have a disease-centred focus.
              However functional medicine, the medical basis for Personalised
              Health, addresses not just a set of symptoms seen in isolation but
              the whole, unique person offering a more patient-centred approach.
            </p>
            <p class>
              Some people have deeper complications that need even more testing
              and understanding but for most people this is enough. If we can
              support you and personalise this support for you then this creates
              the foundation for good long-term health, excellent longevity and
              very low risk of disease.
            </p>
          </div>
        </div>

        <featured-mags></featured-mags>

        <div class="container my-5">
          <div class="row">
            <div class="col-md-10 mx-auto text-center">
              <a
                href="https://amchara360.com/complimentary-consultation"
                class="btn btn-orange-gradient enquire-now-button"
                >ENQUIRE NOW</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Health optimisation Section -->
      <div class="container mt-5">
        <div class="row">
          <div class="col-md-9 mx-auto">
            <div class="h3 mb-2 text-green text-center">
              Health optimisation
            </div>
            <div class="row">
              <div class="col-md-12 mx-auto">
                <div class="row">
                  <div class="col-md-6 mx-auto mb-5">
                    <img
                      width="100%"
                      src="@/assets/images/pages/whats-included/360-triangle.jpg"
                      alt="360 triangle image"
                    />
                  </div>
                  <div class="col-12 text-center">
                    <p class>
                      Our 360 ̊ health programme is designed to support you to
                      long-term, optimal health. We aim to empower you to
                      increase your longevity and quality of life, reduce the
                      risk of chronic diseases like cancer, arthritis, diabetes,
                      and Alzheimer’s and also to reduce the risk of heart
                      attacks.
                    </p>
                    <p>
                      To achieve this we focus on 3 areas vital for your health:
                      your hormone health, including the endocrine system and
                      your adrenals, your thyroid and main sex hormones, your
                      genetics and your digestive tract.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Gut health Section -->
      <div class="container">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="row">
              <div class="col-md-8 text-center">
                <div class="h3 mb-2 text-green">Gut Health</div>

                <p class="h2 orange-text">“All disease begins in the gut”</p>
                <p class>
                  <span style="font-weight: 800">Hippocrates</span>
                </p>
                <p class="darkgreen-text">
                  ...so therefore health must begin in the gut too
                </p>
                <p class>
                  Normally chronic health conditions stem from the gut. We
                  regard gut-health as a vital cornerstone for foundation of
                  complete health, 40% of adults in the UK present with IBS in
                  their lifetime.
                </p>
                <p class>
                  People say we are what we eat, this is not quite true. We are
                  in-fact what we assimilate (absorb).
                </p>
                <p class>
                  Over the last decade our understanding of gut health and the
                  importance of a healthy microbiome has accelerated. Even
                  genetic diseases can be affected by the balance of our gut
                  bacteria since this influences the way our genes are
                  expressed.
                </p>
              </div>
              <div class="col-md-4 mb-5 mb-md-0 pt-md-5">
                <img
                  src="@/assets/images/pages/360/gut-man.jpg"
                  width="100%"
                  alt="Amchara Gut Health"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-9 mx-auto text-center">
                <p class="darkgreen-text h4">
                  Compromised gut health is associated with:
                </p>
                <p class="orange-text">
                  Poor immunity • Increased risk of cancer Fatigue • IBS •
                  Crohn’s Disease • Systemic inflammation Autoimmune conditions
                  • Autism • Food sensitivities • Hormonal imbalances • Skin
                  conditions Obesity • Arthritis • Allergies
                </p>
                <p class>
                  Having a healthy gut microbiome is vital for your immunity and
                  your optimal health. Your gut hosts 70-80% of your body’s
                  immune cells.
                </p>
                <p class="darkgreen-text h4">
                  What happens with good gut health?
                </p>
                <p class>
                  <span style="font-weight: 800">Absorption:</span> This is the
                  process by which the nutrients in food are passed on to the
                  blood.
                  <br />
                  <span style="font-weight: 800">Immunity:</span> We have around
                  10 trillion human cells and 100 trillion bacteria cells in our
                  body.
                  <br />
                  <span style="font-weight: 800">Digestion:</span> The process
                  by which food is broken down into simple chemical compounds
                  that can be absorbed and used as nutrients or eliminated by
                  the body.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row px-3">
        <div
          class="col-12 col-md-9 mx-md-auto p-4 pb-3"
          style="border: 1px solid green; padding-top: 0rem"
        >
          <div class="h3 mb-2 text-green">Test don't guess!</div>
          <div class="row">
            <div class="col-12">
              <p>
                At Amchara we offer you the opportunity to have a highly
                comprehensive test that analyses your digestive tract to
                objectively evaluate the status of your gut health and identify
                contributing factors to gastrointestinal complaints and other
                chronic system conditions.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-7">
              <div class="row">
                <div class="col-md-12">
                  <p class>
                    <span style="font-weight: 800"
                      >The extensive panel provides key information on the
                      following areas:</span
                    >
                  </p>
                  <ul class="home-bullet-points-left">
                    <li class="p-list-item mb-3 mb-md-0">
                      Parameters for digestion and absorption
                    </li>
                    <li class="p-list-item mb-3 mb-md-0">Inflammation</li>
                    <li class="p-list-item mb-3 mb-md-0">Immunity</li>
                    <li class="p-list-item mb-3 mb-md-0">Leaky gut</li>
                    <li class="p-list-item mb-3 mb-md-0">
                      Quality of gut microbiome
                    </li>
                    <li class="p-list-item mb-3 mb-md-0">
                      Presence of yeast overgrowth
                    </li>
                    <li class="p-list-item mb-3 mb-md-0">
                      Parasitic infections
                    </li>
                    <li class="p-list-item mb-3 mb-md-0">
                      Susceptibility testing to prescriptive and natural agents
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <img
                width="100%"
                src="@/assets/images/pages/360/testing-image.png"
                alt="Test dont guess gut image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr width="80%" class="mt-5" />

    <div class="container mt-5" id="enquiry-section">
      <div class="row text-center">
        <div class="col-md-12 mx-auto">
          <h2 class="h3 font-weight-bold text-success">
            Enquire about 360° health today!
          </h2>
          <h3>Complete the enquiry form below and we’ll call you back.</h3>
          <p><span class="text-danger">*</span> Required info.</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mb-4 mx-auto">
          <form
            action="/enquiries"
            method="post"
            autocomplete="off"
            id="enquiry-form"
          >
            <input type="hidden" name="source" value="360_form" />
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="firstNameInput">
                    First Name
                    <span style="color: red">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="firstNameInput"
                    name="first_name"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="surnameInput">
                    Surname
                    <span style="color: red">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="surnameInput"
                    name="last_name"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="emailInput">
                    Email
                    <span style="color: red">*</span>
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="emailInput"
                    name="email"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="telephoneInput">
                    Telephone
                    <span style="color: red">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="telephoneInput"
                    name="phone"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label for="questionsTextarea">Comments</label>
              </div>
              <div class="col-md-9 my-auto">
                <textarea
                  class="form-control"
                  id="questionsTextarea"
                  name="questions"
                  style="height: 78px; resize: none"
                ></textarea>
              </div>
              <div class="col-md-3 my-5 my-md-auto">
                <button
                  type="submit"
                  class="g-recaptcha btn btn-success"
                  data-sitekey
                  data-callback="onSubmit"
                  style="padding: 10px 40px"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <hr width="80%" class="mt-5" />

    <!-- Hormone health Section -->
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-12 mx-auto">
          <div class="row">
            <div class="col-md-8 text-center">
              <div class="h3 mb-2 text-green">Hormone Health</div>
              <p class="h2 orange-text">
                “A well-functioning endocrine system is vital to homeostasis”
              </p>
              <p class>
                <span style="font-weight: 800"
                  >Claire Mahy, 360&deg; Wellness Expert</span
                >
              </p>
              <p class>
                Good hormone health is fundamental to 360&deg; health. If one of
                your hormones is sub-optimal then this can impact the harmony of
                your whole hormone health.
              </p>
              <p class>
                At Amchara we examine the key pillars of hormone health; the
                adrenal gland, thyroid gland and sex hormones. If one pillar is
                overloaded or weakened, then the whole structure can be
                compromised.
              </p>
            </div>
            <div class="col-md-4 pt-md-5 mb-5 mb-md-0">
              <img
                src="@/assets/images/pages/360/hormone-health.jpg"
                width="100%"
                alt="Amchara Hormone Health"
              />
            </div>
            <div class="row px-3">
              <div class="col-md-9 mx-auto text-center">
                <p class="darkgreen-text ms-md-3 h4">
                  Hormone imbalances are associated with:
                </p>
                <p class="orange-text ms-md-3">
                  Fatigue • Weight gain or loss • Menstrual Irregularities •
                  Infertility • Low libido • Depression Anxiety • Brain fog •
                  Palpitations • Migraines • Insomnia • Skin conditions • Hair
                  loss Digestive issues
                </p>
                <p class="darkgreen-text ms-md-3 h4">The seven main hormones</p>
                <p class="ms-md-3">
                  Hormones are powerful chemicals and extremely important, they
                  help regulate our heart rate, metabolism, appetite, blood
                  sugar levels, mineral balance, digestion, reproduction,
                  growth, mood and sleep.
                </p>
                <p class="ms-md-3">
                  Out of the 100 or so hormones there are seven major hormones
                  that strongly affect how we look, feel, think and behave.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Circle Slider -->
    <div class="container my-4 circle_slider" data-slider-id="2">
      <div class="row noselect">
        <div class="col-auto mx-auto">
          <!-- small green outer circle left -->
          <div
            class="green_circle outer_circle d-none d-lg-inline-block"
            data-position="outer_left"
          >
            <div class="green_circle_outer_left_text"></div>
            <div class="green_circle_outer_arrow"></div>
          </div>
          <!-- large green inner circle left -->
          <div
            class="green_circle inner_circle d-none d-lg-inline-block"
            data-position="inner_left"
          >
            <div class="green_circle_inner_left_text"></div>
            <div class="green_circle_inner_arrow"></div>
          </div>
          <div class="circle_slider_mobile_elements">
            <!-- left arrow on mobiles -->
            <div class="slider-arrow d-lg-none mx-3" data-rotate="left">
              <i class="fa fa-angle-left fa-4x" style="color: grey"></i>
            </div>
            <!-- large selected centre circle -->
            <div class="selected_circle selected_circle_orange">
              <div
                class="selected_circle_text selected_circle_text_orange"
              ></div>
              <div
                class="selected_circle_arrow selected_circle_arrow_orange"
              ></div>
            </div>
            <!-- right arrow on mobiles -->
            <div class="slider-arrow d-lg-none mx-3" data-rotate="right">
              <i class="fa fa-angle-right fa-4x" style="color: grey"></i>
            </div>
          </div>
          <!-- large green inner circle right -->
          <div
            class="green_circle inner_circle d-none d-lg-inline-block"
            data-position="inner_right"
          >
            <div class="green_circle_inner_right_text"></div>
            <div class="green_circle_inner_arrow"></div>
          </div>
          <!-- small green outer circle right -->
          <div
            class="green_circle outer_circle d-none d-lg-inline-block"
            data-position="outer_right"
          >
            <div class="green_circle_outer_right_text"></div>
            <div class="green_circle_outer_arrow"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-9 mx-auto">
          <!-- large selected centre circle subheader and description -->
          <div class="text-center">
            <div
              class="selected_circle_subheader selected_circle_subheader_orange my-3"
            ></div>
            <div class="selected_circle_description"></div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row px-md-3">
          <div
            class="col-12 col-md-9 mx-md-auto p-4 pb-3 mt-5"
            style="border: 1px solid green; padding-top: 0rem"
          >
            <div class="h3 mb-2 text-green">Test don't guess!</div>
            <div class="row">
              <div class="col-md-7">
                <p class>
                  To help you explore your hormone health Amchara offer
                  comprehensive laboratory tests. These advanced tests can
                  measure all of your key hormones. These extensive panels use
                  combinations of dried urine, saliva swabs and blood for
                  accurate and reliable data.
                </p>
                <p>
                  This test data helps us to discover exactly where any health
                  problem might lie and be compromising your health. From which
                  we can personalise a plan tailored exactly for your health
                  needs.
                </p>
                <p>
                  As part of your hormone health experience, our expert team can
                  guide you to select the appropriate functional test for you
                  and your health needs. Once your tests results are returned by
                  the biomedical labs then we can review your results and
                  support you with a complete clinical assessment supported with
                  an in-depth virtual consultation before forming your bespoke
                  plan, personalised to your lifestyle and health needs.
                </p>
              </div>
              <div class="col-md-5">
                <img
                  width="100%"
                  src="@/assets/images/pages/360/dont-guess.jpg"
                  alt="Test dont guess gut image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container mt-5">
        <div class="row">
          <div class="col-md-10 mx-auto text-center">
            <a
              href="https://amchara360.com/complimentary-consultation"
              class="btn btn-orange-gradient enquire-now-button"
              >ENQUIRE NOW</a
            >
          </div>
        </div>
      </div>

      <hr width="80%" class="mt-5" />

      <!-- Genetics Section -->
      <div class="container mb-4 mt-5">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="row">
              <div class="col-md-7 text-center">
                <div class="h3 mb-2 text-green mb-2">
                  Genetics & Epigenetics
                </div>
                <p class="h2 orange-text">
                  “One man’s meat is another man’s poison”
                </p>
                <p class>
                  <span style="font-weight: 800">Lucretius 1st Century BC</span>
                </p>
                <p class="h4 text-primary">Nutrigenomics vs Nutrigenetics</p>
                <p class>
                  Nutrigenomics is the study of how food and nutrients affect
                  our gene expression. For example; how do omega 3 acids in oily
                  fish “switch off” part of the immune system to prevent excess
                  inflammation? Our ‘gene expression’ is impacted by what we
                  eat. Gene expression is also called epigenetics, this is the
                  study of how our genes respond to their environment.
                </p>
              </div>
              <div class="col-md-5">
                <img
                  src="@/assets/images/pages/360/fuzzy.jpg"
                  class="mt-2"
                  width="110%"
                  alt="Amchara Hormone Health"
                />
              </div>
              <div class="row">
                <div class="col-md-9 mx-auto mt-4 text-center">
                  <p class="ms-md-3">
                    Nutrigenetics is how our DNA and genetic variations affect
                    our response to nutrients. For example; how a variation on
                    the MTHFR gene affects our ability to use the vitamin folate
                    – is nutrigenetics.
                  </p>
                  <p class="ms-md-3">
                    Knowledge of our specific nutrigenetic expression creates an
                    opportunity to make positive changes to reduce our
                    susceptibility to illness and disease.
                  </p>
                  <p class="h4 ms-md-3 text-primary">Genetic expression</p>
                  <p class="ms-md-3">
                    While we can’t control our genes, we can affect their
                    expression — whether they’re likely to get “switched on” or
                    “off”. This is referred to as ‘genetic expression’ and is
                    strongly shaped by our environment. If we know you have a
                    genetic manifestation that means you are susceptible to
                    weight gain or a disease then our approach is that you bathe
                    those genes in goodness and reduce your risk of disease or
                    aid recovery.
                  </p>
                  <p class="text-primary ms-md-3" style="font-weight: 800">
                    Once we know the details of your genetic variants, then we
                    are able to produce you a personalised lifestyle and
                    nutritional plan. These changes can strengthen your bodies
                    resilience to common chronic illnesses and aid recovery from
                    some chronic disease.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row px-md-3">
          <div
            class="col-12 col-md-9 mx-md-auto p-4 pb-3"
            style="border: 1px solid green; padding-top: 0rem"
          >
            <div class="h3 mb-2 text-green">Test don't guess!</div>
            <div class="row">
              <div class="col-md-7">
                <p class>
                  The sample collection is straightforward. A simple saliva swab
                  is all we need. The results are sent to our partner laboratory
                  and the comprehensive test gives us deep analysis of how your
                  genetic make-up and your gene expression.
                </p>
                <p>
                  Once we have your results returned you will receive your
                  comprehensive personalised report and a 1:1 consultation
                </p>
              </div>
              <div class="col-md-5">
                <img
                  width="100%"
                  src="@/assets/images/pages/360/dont-guess-2.jpg"
                  alt="Test dont guess gut image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container mt-5">
        <div class="row">
          <div class="col-md-10 mx-auto text-center">
            <a
              href="https://amchara360.com/complimentary-consultation"
              class="btn btn-orange-gradient enquire-now-button"
              >ENQUIRE NOW</a
            >
          </div>
        </div>
      </div>

      <hr width="80%" class="mt-5" />

      <team></team>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <trip-advisor></trip-advisor>

      <div class="mt-2 mb-2">&nbsp;</div>
      <hr width="80%" />
      <locations></locations>

      <div class="container mt-5">
        <div class="row">
          <div class="col-md-10 mx-auto text-center">
            <a
              href="https://amchara360.com/complimentary-consultation"
              class="btn d-inline-block btn-orange-gradient enquire-now-button"
              >ENQUIRE NOW</a
            >
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <testimonials></testimonials>

      <div class="mt-3 mb-3">&nbsp;</div>
      <!--<hr width="80%" />
      <instagram></instagram>-->
    </div>

    <newsletter-cta></newsletter-cta>
  </div>
</template>

<script>
import FeaturedIn from "./page-partials/FeaturedIn";
import Personalised from "./page-partials/Personalised";
import FeaturedMags from "./page-partials/FeaturedMags";
import Locations from "./page-partials/Locations";
import TripAdvisor from "./page-partials/TripAdvisor";
import Testimonials from "./page-partials/Testimonials";
import Instagram from "./page-partials/Instagram";
import NewsletterCta from "./page-partials/NewsletterCta";
import Team from "./page-partials/Team";

export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
  data() {
    return {
      bgImages: [require("@/assets/images/pages/360/header.jpg")],
    };
  },
  mounted() {
    var sliders = {
      slider_1: {
        inner_left: {
          title: "Hormone Health",
          subheader: "Your Hormone Health",
          description:
            "Hormones are powerful chemicals and extremely important, they help regulate our heart rate, metabolism, appetite, blood sugar levels, mineral balance, digestion, reproduction, growth, mood and sleep. If one of your hormones is sub-optimal then this can impact the harmony of your overall 360 health. Amchara’s advanced tests can measure all of your key hormones to discover exactly where any health problem might lie.",
        },
        middle: {
          title: "Genetics",
          subheader: "Your Genetics",
          description:
            "While we can’t control our genes, we can affect their expression - whether they’re likely to get “switched on” or “off”. This is referred to as ‘genetic expression’ and is strongly shaped by our environment. If we know you have a genetic manifestation that suggests you are susceptible to weight gain or a disease, then our approach is that you bathe those genes in goodness and reduce your risk of disease or aid recovery. Your test with Amchara will provide a deep analysis of your genetic make-up and your gene expression.",
        },
        inner_right: {
          title: "Gut Health",
          subheader: "Your Gut Health",
          description:
            "Normally chronic health conditions stem from the gut. We regard gut-health as a vital cornerstone for the foundation of complete optimal health. We can offer you the opportunity to have a highly comprehensive test that analyses your digestive tract to objectively evaluate the status of your gut health and identify contributing factors to gastrointestinal complaints and other chronic health conditions.",
        },
      },
      slider_2: {
        outer_left: {
          title: "Oestrogen, Progesterone, Testosterone",
          subheader:
            "Oestrogen, Progesterone, Testosterone, your main sex hormones",
          description:
            "Your main sex hormones that are produced by both men and women, although in different amounts. Oestrogen is the primary female sex hormone which functions to promote growth and reproduction. It works in tandem with its sister hormone progesterone to balance and control the activities of oestrogen. Testosterone is the primary male sex hormone is responsible for fat distribution, muscle and bone mass, body hair and our sex drive.",
        },
        inner_left: {
          title: "Insulin",
          subheader: "Insulin, your blood sugar regulator",
          description:
            "Insulin is released from your pancreas in response to raised blood sugar levels; it works in tandem with cortisol to maintain blood sugar levels within the correct balance.",
        },
        middle: {
          title: "Cortisol",
          subheader: "Cortisol, your stress hormone",
          description:
            "Cortisol is a major player when it comes to helping your body cope with stress. Working closely beside it is oestrogen which plays a key role in managing cortisol so that it stays within normal levels.",
        },
        inner_right: {
          title: "Thyroid",
          subheader: "Thyroid, your metabolism hormone",
          description:
            "Your thyroid is a vital gland affecting the metabolism of every cell in your body. The hormones released from your thyroid play a critical role in your blood glucose levels, body temperature, heart rate, growth and development and your nervous system activity.",
        },
        outer_right: {
          title: "DHEA",
          subheader: "DHEA, your repair hormone",
          description:
            "DHEA is a substance produced by the adrenal glands and used by your body to make steroid hormones such as oestrogen, progesterone and testosterone. It has an antagonistic influence on cortisol limiting many of its detrimental effects, thereby reducing stress, improving sleep, immune function, cardiovascular health, energy, weight, mood and mental clarity.",
        },
      },
    };

    $(document).ready(function () {
      // on page load
      $(".circle_slider").each(function () {
        var sliderID = $(this).data("slider-id");

        if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
          $(this)
            .find(".green_circle_outer_left_text")
            .html(sliders["slider_" + sliderID]["outer_left"]["title"]);
        }
        $(this)
          .find(".green_circle_inner_left_text")
          .html(sliders["slider_" + sliderID]["inner_left"]["title"]);
        $(this)
          .find(".selected_circle_text")
          .html(sliders["slider_" + sliderID]["middle"]["title"]);
        $(this)
          .find(".green_circle_inner_right_text")
          .html(sliders["slider_" + sliderID]["inner_right"]["title"]);
        if (sliders["slider_" + sliderID]["outer_right"] != undefined) {
          $(this)
            .find(".green_circle_outer_right_text")
            .html(sliders["slider_" + sliderID]["outer_right"]["title"]);
        }

        $(this)
          .find(".selected_circle_subheader")
          .html(sliders["slider_" + sliderID]["middle"]["subheader"]);
        $(this)
          .find(".selected_circle_description")
          .html(sliders["slider_" + sliderID]["middle"]["description"]);
      });

      // desktop event listener
      $(".green_circle").click(function () {
        var this_circle_slider = $(this).closest(".circle_slider");
        var sliderID = this_circle_slider.data("slider-id");
        var position = $(this).data("position");

        // switch the newly selected object with the previously selected object
        var previously_selected_obj = sliders["slider_" + sliderID]["middle"];
        var newly_selected_obj = sliders["slider_" + sliderID][position];
        sliders["slider_" + sliderID]["middle"] = newly_selected_obj;
        sliders["slider_" + sliderID][position] = previously_selected_obj;

        // now update the objects on screen
        $(this)
          .find(".green_circle_" + position + "_text")
          .html(previously_selected_obj.title);
        $(this_circle_slider)
          .find(".selected_circle_text")
          .html(newly_selected_obj.title);
        $(this_circle_slider)
          .find(".selected_circle_subheader")
          .html(newly_selected_obj.subheader);
        $(this_circle_slider)
          .find(".selected_circle_description")
          .html(newly_selected_obj.description);
      });

      // mobile event listener
      $(".slider-arrow").click(function () {
        var this_circle_slider = $(this).closest(".circle_slider");
        var sliderID = this_circle_slider.data("slider-id");
        var sliderRotate = $(this).data("rotate");

        // move all objects up or down one depending on whether left (down) or right (up) arrow was pressed
        if (sliderRotate == "left") {
          var newly_selected_obj = sliders["slider_" + sliderID]["inner_left"];
          if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
            // 5 circle slider
            sliders["slider_" + sliderID]["inner_left"] =
              sliders["slider_" + sliderID]["outer_left"];
            sliders["slider_" + sliderID]["outer_left"] =
              sliders["slider_" + sliderID]["outer_right"];
            sliders["slider_" + sliderID]["outer_right"] =
              sliders["slider_" + sliderID]["inner_right"];
          } else {
            // 3 circle slider
            sliders["slider_" + sliderID]["inner_left"] =
              sliders["slider_" + sliderID]["inner_right"];
          }
          sliders["slider_" + sliderID]["inner_right"] =
            sliders["slider_" + sliderID]["middle"];
          sliders["slider_" + sliderID]["middle"] = newly_selected_obj;
        } else if (sliderRotate == "right") {
          var newly_selected_obj = sliders["slider_" + sliderID]["inner_right"];
          if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
            // 5 circle slider
            sliders["slider_" + sliderID]["inner_right"] =
              sliders["slider_" + sliderID]["outer_right"];
            sliders["slider_" + sliderID]["outer_right"] =
              sliders["slider_" + sliderID]["outer_left"];
            sliders["slider_" + sliderID]["outer_left"] =
              sliders["slider_" + sliderID]["inner_left"];
          } else {
            // 3 circle slider
            sliders["slider_" + sliderID]["inner_right"] =
              sliders["slider_" + sliderID]["inner_left"];
          }
          sliders["slider_" + sliderID]["inner_left"] =
            sliders["slider_" + sliderID]["middle"];
          sliders["slider_" + sliderID]["middle"] = newly_selected_obj;
        }

        // now update the objects on screen
        // including the (hidden on mobile) green circles incase
        // they are on desktop and expand the vieport
        if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
          $(this_circle_slider)
            .find(".green_circle_outer_left_text")
            .html(sliders["slider_" + sliderID]["outer_left"]["title"]);
        }
        $(this_circle_slider)
          .find(".green_circle_inner_left_text")
          .html(sliders["slider_" + sliderID]["inner_left"]["title"]);
        $(this_circle_slider)
          .find(".selected_circle_text")
          .html(sliders["slider_" + sliderID]["middle"]["title"]);
        $(this_circle_slider)
          .find(".green_circle_inner_right_text")
          .html(sliders["slider_" + sliderID]["inner_right"]["title"]);
        if (sliders["slider_" + sliderID]["outer_right"] != undefined) {
          $(this_circle_slider)
            .find(".green_circle_outer_right_text")
            .html(sliders["slider_" + sliderID]["outer_right"]["title"]);
        }

        $(this_circle_slider)
          .find(".selected_circle_subheader")
          .html(sliders["slider_" + sliderID]["middle"]["subheader"]);
        $(this_circle_slider)
          .find(".selected_circle_description")
          .html(sliders["slider_" + sliderID]["middle"]["description"]);
      });
    });

    function onSubmit(token) {
      document.getElementById("enquiry-form").submit();
    }
  },
  components: {
    FeaturedIn,
    Personalised,
    FeaturedMags,
    Locations,
    TripAdvisor,
    Testimonials,
    Instagram,
    NewsletterCta,
    Team,
  },
};
</script>

<style>
</style>